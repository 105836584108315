import { Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ApiService } from 'src/app/services/api.service';

@Component({
    selector: 'app-account-history',
    templateUrl: './account-history.component.html',
    styleUrls: ['./account-history.component.scss']
})
export class AccountHistoryComponent implements OnChanges, OnInit {

    @Input() reload!: boolean;
    private initialized = false;
    showHistory = false;
    aoCompanyList: any;

    accountHistory: any;
    public pageIndex: number = 0;
    public currentPageIndex = 0;
    public pageSize = 10;
    showPagination: boolean = false;
    paginatedData: any;
    pageSizeOptions = [10, 20, 50, 100];
    credData: any;
    universalId: any;
    collectionSize: number;
    noHistoryRecords: boolean = false;

    naTooltipText: String = 'This name was not able to be verified by the Responding Financial Institution.'

    constructor(
        private service: ApiService
    ) { 
        this.credData = sessionStorage.getItem('credData');
        this.universalId = sessionStorage.getItem('universalId');
        this.aoCompanyList = JSON.parse(this.credData).results
            .filter((item: any) => item.applicationName === 'accessoptima')
            .map((item: any) => item.compId);
    }

    getBeneficiaryName(item: any): string {
        if (item.beneficiaryType.toUpperCase() === 'INDIVIDUAL' && item.beneficiaryIndividualName) {
            return item.beneficiaryIndividualName;
        } 
        if (item.beneficiaryType.toUpperCase() === 'COMPANY' && item.businessName) {
            return item.businessName
        }
        return 'N/A';
    }

    getDisplayName(item: any): string {
        const beneficiaryName = this.getBeneficiaryName(item).trim();
        return beneficiaryName ? (item.beneficiaryNameMatch || 'N/A') : '';
    }

    ngOnInit(): void {
        this.onHistoryLoad(this.aoCompanyList);
        this.initialized = true;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.initialized && changes['reload'] && changes['reload'].currentValue !== changes['reload'].previousValue) {
            this.onHistoryLoad(this.aoCompanyList);
        }
    }

    onHistoryLoad(aoCompanyList: any) {
        this.showHistory = true;
        this.service.checkAccountsHistory(aoCompanyList, this.universalId).subscribe({
            next: (history) => {
                console.log(history)
                if (history && history.data.length) {
                    this.showHistory = false;
                    this.collectionSize = history?.data?.length;
                    const oneYearAgo = new Date();
                    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

                    // Filter data to only include items from the last 1 year
                    this.accountHistory = history.data.filter(item => {
                        console.log(item.createdDate)
                        const itemDate = new Date(item.createdDate);
                        return itemDate >= oneYearAgo;
                    });
                    console.log(history.data.length, 'history data')
                    if (history.data.length > 10) {
                        this.showPagination = true;
                    }
                } else {
                    this.accountHistory = [];
                    this.showHistory = false;
                    this.noHistoryRecords = true;
                }
            },
            error: (error) => {
                console.log("Couldn't retrieve history data", error);
                this.showHistory = false;
            }
        })
    }

    tablePagination(event: PageEvent) {
        this.currentPageIndex = event.pageIndex;
        this.pageSize = event.pageSize;
    }

    refreshHistory() {
        this.accountHistory = [];
        this.onHistoryLoad(this.aoCompanyList);
    }

}
