export const baseURL = 'https://universalportal-sso.qacitizensbank.com';
export const assetPrefix = "/account-verification-mfe/";
export const assetURL = `${baseURL}${assetPrefix}`;

export const environment = {
  production: false,
  env: 'qa',
  isQA: false,
  isQA2: true,
  isSIT: false,
  isDEV: false,
  title: "Account Verification",
  header: {
    1: { type: 'image', width: '200', alt: 'Citizens Commercial Banking', src: 'https://universalportal-sso.qacitizensbank.com/content/assets/CitizensCommercial_TM_Horz_RGB_HEX.svg' }
  },
  acc_verif_contactMessage: {
    contact1: 'Treasury Solutions specialist',
    contact2: 'Client Services',
    phone1: '877-550-5933',
    phone2: '(401-282-1362)',
    email: 'clientservices@mail.client.citizensbank.com',
    hours: '24 hours a day, 7 days a week'
  },
  acc_verif_tmxProfiledUrlPrefix: `${baseURL}`,
  acc_verif_threatMetrixUrl: 'https://cdn17.citizensbank.com/fp/tags.js',
  acc_verif_threatMetrixProfileDomain: 'cdn17.citizensbank.com',
  acc_verif_orgId: 'dhlrdtzc',
  acc_verif_transmitUrl: `${baseURL}`,
  acc_univ_saveUserProfileJsonData: 'https://universalportal-sso.qacitizensbank.com/auth/persist/updateUserProfileJsonData',
  acc_verif_billingAccountDetails: 'https://universalportal-sso.qacitizensbank.com/utility/v1/billing/billingAccountDetails/',
  acc_verif_accountHistoryDetails: 'https://universalportal-sso.qacitizensbank.com/auth/account-verification/history',
  acc_verif_inquireByRefId: 'https://universalportal-sso.qacitizensbank.com/auth/account-verification/accountInquiryByRefId',
  acc_verif_postAccountValidation: 'https://universalportal-sso.qacitizensbank.com/auth/account-verification/accountInquiry',
  acc_verif_termsAndConditionsCDN: 'https://universalportal-sso.qacitizensbank.com/content/config/terms.json',
  acc_verif_postRoutingNumber: 'https://universalportal-sso.qacitizensbank.com/auth/account-verification/bank-details?routingNumber=',
  acc_verif_profilingFlagCDN: 'https://universalportal-sso.qacitizensbank.com/content/config/acc_verif_profiling_data.json',
  acc_verif_universalLoginCase: 'https://client.qacitizensbank.com/s/servicecase',
  acc_verif_countryDetails: "https://universalportal-sso.qacitizensbank.com/content/config/countries-data.json"
};
