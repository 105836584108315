export const baseURL = 'https://universalportal-sso.qacitizensbank.com';
export const assetPrefix = "/sso-client/";
export const assetURL = `${baseURL}${assetPrefix}`;
export const commercialDigitalGateawayURL = "https://client.qacitizensbank.com";

export const environment = {
  env: 'qa',
  production: false,
  isQA: true,
  isQA2: false,
  isSIT: false,
  isDEV: false,
  aesVariable: '9s8k438599284367',
  siteName: 'Single sign-on',
  apiUrl: 'https://localhost:9091',
  analyticsScriptSrc: '//assets.adobedtm.com/c6a477a8a7f5/791d39f3d4af/launch-3298d2c89a77-development.min.js',
  threatMetrixUrl: 'https://cdn17.citizensbank.com/fp/tags.js', // h.online-metrix.net
  threatMetrixProfileDomain: 'cdn17.citizensbank.com', // cdn17.citizensbank.com. CNAME h-citizenscommercial.online-metrix.net.
  tmxProfiledUrlPrefix: 'https://universalportal.qacitizensbank.com',
  pin: {
    double: 'onechk2.qacitizensbank.com/449440/double.js?dt=login&r=',
    blet: 'onechk2.qacitizensbank.com/449440/blet.js?r='
  },
  transmitUrl: `${baseURL}`,
  transmitToken: 'TSToken 332bcd73-4e8e-4831-ad89-8b8699879e35; tid=commercialotp_lexisnexis_invoke',
  transmitUniversalIdAvailabilityUrl: `${baseURL}/api/v2/server-api/authenticate?aid=commercial_universal_serverapi&uid=`,
  transmitDisabledLegacyAccountUrl: `${baseURL}/api/v2/server-api/authenticate?aid=commercial_universal_serverapi&uid=`,
  transmitOTPUrl: `${baseURL}/api/v2/server-api/anonymous_invoke?aid=commercial_universal_serverapi`,
  orgId: 'dhlrdtzc',
  mobileHeader: {
    1: { type: 'image', width: '250', alt: 'Access Optima', src: `${assetURL}assets/accessOptimaFullImage.png` }
  },
  header: {
    1: {type: 'image', width: '200', alt: 'Citizens Commercial Banking', src: `${assetURL}assets/CitizensCommercial_TM_Horz_RGB_HEX.svg` }
  },
  footer: {
    1: {
      type: 'link',
      text: 'Security',
      href: `${commercialDigitalGateawayURL}/s/article/Security`
    },
    2: {
      type: 'link',
      text: 'Privacy Rights',
      href: 'https://www.citizensbank.com/account-safeguards/privacy.aspx'
    },
    3: {
      type: 'link',
      text: 'Terms of Use',
      href: `${commercialDigitalGateawayURL}/s/article/Terms-of-Use`
    },
    4: { type: 'link', text: 'FDIC Coverage', href: 'https://www.fdic.gov/' },
    5: {
      type: 'link',
      text: 'Site Map',
      href: `${commercialDigitalGateawayURL}/s/sitemap`
    },
    6: { type: 'link', text: 'Citizens Commercial', href: 'https://www.citizensbank.com/corporate-finance/default.aspx' }
  },
  footerCopyright: 'Citizens Financial Group, Inc. All rights reserved. Citizens Commercial Banking is a brand name of Citizens Bank, N.A. Member FDIC.',
  footerDisclaimer: 'Disclaimer: Cash Flow Forecasting provides information for informational purposes only, subject to our terms and conditions. We do not provide financial, accounting, tax, investment, or other professional advice.',
  defaultCancelUrl: `${commercialDigitalGateawayURL}/s/`,
  loginCancelUrlAchieve: `${commercialDigitalGateawayURL}/s/`,
  loginCancelUrlOptima: 'https://gateway.qacitizenscommercialbanking.com/ccp/accessoptima.jsp',
  sessionTimeoutEnabled: false,
  ppAODelay: 500,
  contactMessage: {
    contact1: 'Treasury Solutions specialist',
    contact2: 'Client Services',
    phone1: '877-550-5933',
    phone2: '(401-282-1362)',
    email: 'clientservices@mail.client.citizensbank.com',
    hours: '24 hours a day, 7 days a week'
  },
  applicationName: {
    achieve: 'web_achieve_access',
    accessoptima: 'web_access_optima',
    trade360: 'web_trade360',
    seeburger: 'web_seeburger',
    smb: 'web_cashflow'
  },
  application1: 'achieve',
  application2: 'accessoptima',
  univCountryCodes: [
    { country: 'United States', countryCode: 'us', code: '+1' },
    { country: 'Argentina', countryCode: 'ar', code: '+54' },
    { country: 'Armenia', countryCode: 'am', code: '+374' },
    { country: 'Australia', countryCode: 'au', code: '+61' },
    { country: 'Austria', countryCode: 'at', code: '+43' },
    { country: 'Bahamas', countryCode: 'bs', code: '+1-242' },
    { country: 'Bahrain', countryCode: 'bh', code: '+973' },
    { country: 'Belgium', countryCode: 'be', code: '+32' },
    { country: 'Brazil', countryCode: 'br', code: '+55' },
    { country: 'Canada', countryCode: 'ca', code: '+1' },
    { country: 'Chile', countryCode: 'cl', code: '+56' },
    { country: 'China', countryCode: 'cn', code: '+86' },
    { country: 'Colombia', countryCode: 'co', code: '+57' },
    { country: 'Costa Rica', countryCode: 'cr', code: '+506' },
    { country: 'Denmark', countryCode: 'dk', code: '+45' },
    { country: 'Egypt', countryCode: 'eg', code: '+20' },
    { country: 'Finland', countryCode: 'fi', code: '+358' },
    { country: 'France', countryCode: 'fr', code: '+33' },
    { country: 'Germany', countryCode: 'de', code: '+49' },
    { country: 'Greece', countryCode: 'gr', code: '+30' },
    { country: 'Hong Kong', countryCode: 'hk', code: '+852' },
    { country: 'Hungary', countryCode: 'hu', code: '+36' },
    { country: 'India', countryCode: 'in', code: '+91' },
    { country: 'Indonesia', countryCode: 'id', code: '+62' },
    { country: 'Ireland', countryCode: 'ie', code: '+353' },
    { country: 'Italy', countryCode: 'it', code: '+39' },
    { country: 'Jamaica', countryCode: 'jm', code: '+1-876' },
    { country: 'Japan', countryCode: 'jp', code: '+81' },
    { country: 'Luxembourg', countryCode: 'lu', code: '+352' },
    { country: 'Malaysia', countryCode: 'my', code: '+60' },
    { country: 'Mexico', countryCode: 'mx', code: '+52' },
    { country: 'Netherlands', countryCode: 'nl', code: '+31' },
    { country: 'New Zealand', countryCode: 'nz', code: '+64' },
    { country: 'Norway', countryCode: 'no', code: '+47' },
    { country: 'Oman', countryCode: 'om', code: '+968' },
    { country: 'Panama', countryCode: 'pa', code: '+507' },
    { country: 'Peru', countryCode: 'pe', code: '+51' },
    { country: 'Philippines', countryCode: 'ph', code: '+63' },
    { country: 'Poland', countryCode: 'pl', code: '+48' },
    { country: 'Portugal', countryCode: 'pt', code: '+351' },
    { country: 'Puerto Rico', countryCode: 'pr', code: '+1-787' },
    { country: 'Russia', countryCode: 'ru', code: '+7' },
    { country: 'Singapore', countryCode: 'sg', code: '+65' },
    { country: 'South Africa', countryCode: 'za', code: '+27' },
    { country: 'South Korea', countryCode: 'kr', code: '+82' },
    { country: 'Spain', countryCode: 'es', code: '+34' },
    { country: 'Sweden', countryCode: 'se', code: '+46' },
    { country: 'Switzerland', countryCode: 'ch', code: '+41' },
    { country: 'Taiwan', countryCode: 'tw', code: '+886' },
    { country: 'Thailand', countryCode: 'th', code: '+66' },
    { country: 'Turkey', countryCode: 'tr', code: '+90' },
    { country: 'United Arab Emirates', countryCode: 'ae', code: '+971' },
    { country: 'United Kingdom', countryCode: 'gb', code: '+44' },
    { country: 'Venezuela', countryCode: 've', code: '+58' },
    { country: 'Vietnam', countryCode: 'vn', code: '+84' }
  ],
  univ_session: {
    basePath: `${baseURL}/auth/personaDetails`,// 'http://localhost:3000/',
    credentials: '',
    mobileCredentials: 'mobileLinkedCredentials'
  },
  univ_oidc: {
    url: 'https://pf-fam-qa.qacitizensbank.com/as/authorization.oauth2',
    redirect_url: `${baseURL}/sso/token`,
    client_id: 'CommercialUniversalWeb'
  },
  univ_portal_transmitJourney: 'web_universal_portal',
  univ_relayStateEmailID: 'clientservices@qacitizensbank.com',
  univ_relayStateEmail: `${baseURL}/auth/config/sfEmailAlert`,
  univ_getAdminData: `${baseURL}/auth/transmit/getAuthData`,
  univ_tokenBlob: 'Basic ZEhKaGJuTnRhWFE9Ok1VTkVNRFl3TTBNNFJUaEVOMEkxTURsQ1F6VTFORUU1TURkRk1VTkZRek15TURZeFJFSTFRVUZFUlRBMU5rRTVRVVZEUkRReE9URXlOemRHTUVNeQ==',

  // config
  univ_apps_url: `${baseURL}/auth/config/feContext`,
  univ_applications: [
    {
      name: 'accessoptima',
      displayName: 'accessOPTIMA',
      oudAppName: 'MMGPS',
      isRegistered: true,
      isTradeMarked: false,
      isActive: true,
      appLoginJson: {
        transmitJourney: 'web_access_optima',
        samlActionUrl: 'https://9471-sbx.btbanking.com/Shibboleth.sso/SAML2/POST',
        relayState: 'https://9471-tst.btbanking.com/ui/',
        logoutUrl: `${commercialDigitalGateawayURL}/s/`,
        mobileLogoutUrl: 'https://gateway.qacitizenscommercialbanking.com/ccp/accessoptima-mobile.jsp'
      }
    },
    {
      name: 'achieve',
      displayName: 'ACHieve Access',
      oudAppName: 'ACHIEVE',
      isRegistered: true,
      isTradeMarked: false,
      isActive: true,
      appLoginJson: {
        transmitJourney: 'web_achieve_access',
        samlActionUrl: 'https://qaachieveaccess.qacitizenscommercialbanking.com/exchange/sso/login',
        relayState: 'token'
      }
    }, {
      name: 'trade360',
      displayName: 'accessGLOBAL Trade Flow',
      oudAppName: 'GTF',
      isRegistered: true,
      isTradeMarked: false,
      isActive: true,
      appLoginJson: {
        transmitJourney: 'web_trade360'
      }
    },
    {
      name: 'seeburger',
      displayName: 'Citizens File Gateway',
      oudAppName: 'STERLING',
      isRegistered: true,
      isTradeMarked: false,
      isActive: true,
      appLoginJson: {
        transmitJourney: 'web_seeburger'
      }
    },
    {
      name: 'CASHFLOW',
      displayName: 'Cash Flow Essentials',
      oudAppName: 'CASHFLOW',
      isRegistered: false,
      isTradeMarked: true,
      isActive: true,
      appLoginJson: {
        transmitJourney: 'web_cashflow'
      }
    }
  ],
  univ_desktop_landingLayout: {
    results: [
      {
        pageName: 'landing',
        view: 8,
        layout: [
          {
            column: 1,
            order: 1,
            widgetName: 'linkedCredentials',
            widgetJson: {
              title: 'My Services',
            }
          },
          {
            column: 1,
            order: 2,
            widgetName: 'parcelMonit',
            widgetJson: {
              title: '',
            }
          },
          {
            column: 1,
            order: 3,
            widgetName: 'treasuryLogin',
            widgetJson: {
              title: 'Commercial Services',
            }
          },
          {
            column: 1,
            order: 4,
            widgetName: 'helpCenter',
            widgetJson: {
              title: 'Need Help?',
            }
          },
          {
            column: 2,
            order: 1,
            widgetName: 'sprinklrContent',
            widgetJson: {
              title: 'Commercial Banking News',
            }
          }
        ]
      },
      {
        pageName: 'landing',
        view: 12,
        layout: [
          {
            column: 1,
            order: 1,
            widgetName: 'linkedCredentials',
            widgetJson: {
              title: 'Your Linked Profiles',
            }
          },
          {
            column: 1,
            order: 2,
            widgetName: 'parcelMonit',
            widgetJson: {
              title: '',
            }
          },
          {
            column: 1,
            order: 3,
            widgetName: 'smbUserLogin',
            widgetJson: {
              title: 'Knowledge Center',
            }
          },
          {
            column: 1,
            order: 4,
            widgetName: 'helpCenter',
            widgetJson: {
              title: 'Need Help?',
            }
          }
        ]
      }
    ]
  },
  univ_mobile_landingLayout: {
    results: [
      {
        pageName: 'landing',
        view: 12,
        layout: [
          {
            column: 1,
            order: 1,
            widgetName: 'mobileLinkedCredentials',
            widgetJson: {
              title: 'My Services',
              isOpen: true
            }
          }
        ]
      }
    ]
  },
  univ_sfdc: {
    // authTokenUrl: 'https://citizensbank-commercial--qa.sandbox.my.salesforce.com/services/oauth2/token',
    bannerContentUrl: `${commercialDigitalGateawayURL}/services/apexrest/displaybanner`,
    // clientId: '3MVG9_4NR96cWRpGL9Zm2Lp95ZgpEsmohZlz6.HbcYayjJDbhLjNYHOjNZK5q0pr482r36nG0kmzXWaZs.QUP',
    // clientSecret: '3EE7B3B05AF288F1130792602EDA27371BADC373BD7032485541E466D499AAED',
    // uun: 'saketh.mysa@citizensbank.com.qa',
    // uupd: 'ABCabc123@',
    sfURIPath: '/s/'
  },
  univ_helpCenter: {
    universalLoginUrl: `${commercialDigitalGateawayURL}/s/knowledge-center`,
    butlerUrl: `${commercialDigitalGateawayURL}/s/DigitalButler`,
    butlerUrlHome: `${commercialDigitalGateawayURL}/s/`,
    knowledgeCenterUrl: `${commercialDigitalGateawayURL}/s/knowledgecenter`,
    contactUsUrl: 'https://concierge.citizensbank.com/s/contactsupport',
    findSupport: `${commercialDigitalGateawayURL}/s/findsupport`,
    openServiceCaseUrl: `${commercialDigitalGateawayURL}/s/servicecase`,
    unauthenticatedDigitialConcierge: 'https://concierge.citizensbank.com/s/',
    butlerLogoutUrl: `${baseURL}/sso/uportal/login?app=butler&mobile=true`
  },
  univ_helpSearchUrl: {
    knowledgeUrl: 'https://concierge.citizensbank.com/s/knowledge-center'
  },
  univ_treasuryLogin: [
    {
      name: 'accessdeposit',
      displayName: 'accessDEPOSIT',
      isRegistered: true,
      homeURL: 'https://preprod.itms-online.com/citizenscommercialbankingaccessdeposit.aspx',
      learnMoreURL: 'https://www.citizensbank.com/corporate-finance/treasury-management/managing-receivables/accessdeposit.page',
      isDisabled: false
    },
    {
      name: 'accesslockbox',
      displayName: 'accessLOCKBOX',
      isRegistered: true,
      homeURL: 'https://receivablesuat.regulusgroup.com/lockbox/default.htm',
      learnMoreURL: 'https://www.citizensbank.com/corporate-finance/treasury-management/managing-receivables/lockbox.page',
      isDisabled: false
    },
    {
      name: 'askDigitalButler',
      displayName: 'Ask Digital Butler',
      isRegistered: true,
      homeURL: '',
      learnMoreURL: '',
      isDisabled: false
    },
    {
      name: 'commercialcreditcard',
      displayName: 'Commercial Credit Card',
      isRegistered: false,
      homeURL: 'https://www.centresuite.com/Centre/Public/Logon/Index?ReturnUrl=%2fcentre%3fCitizens&Citizens',
      learnMoreURL: 'https://www.citizensbank.com/corporate-finance/credit-card-and-payment-services.aspx?',
      isDisabled: false
    },
    {
      name: 'paymodex',
      displayName: 'Paymode-X',
      isRegistered: true,
      homeURL: 'https://secure.paymode.com/px/login',
      learnMoreURL: 'https://www.citizensbank.com/corporate-finance/treasury-management/making-payments/integrated-payables.aspx',
      isDisabled: false
    },
    {
      name: 'retaillockbox',
      displayName: 'Retail Lockbox',
      isRegistered: false,
      homeURL: 'https://www.fislbxcentral.com/lms/',
      learnMoreURL: 'https://www.citizensbank.com/corporate-finance/treasury-management/managing-receivables/lockbox.page',
      isDisabled: false
    },
    {
      name: 'sweepnet',
      displayName: 'SweepNet',
      isRegistered: true,
      homeURL: 'https://sweepnet.qacitizenscommercialbanking.com/LoginMain.aspx',
      learnMoreURL: 'https://www.citizensbank.com/small-business/cash-management/sweep-account.aspx?',
      isDisabled: false
    }
  ],

  univ_smbUserLogin: [
    {
      name: 'User guide',
      displayName: 'User guide',
      isRegistered: true,
      description: 'Want a get more into the details?Our user guide has all the info you need.',
      homeURL: 'https://client.qacitizensbank.com/s/article/User-Guide',
      learnMoreURL: 'https://client.qacitizensbank.com/s/article/User-Guide',
      isDisabled: false,
      linkText: 'Download',
      src: `${assetURL}assets/docSmb.png`
    },
    {
      name: 'Quick Start guide',
      displayName: 'Quick Start guide',
      isRegistered: true,
      description: 'Want a fast-track your set-up? Our quick start guide can help you get up and running.',
      homeURL: 'https://client.qacitizensbank.com/s/article/Quick-Start-Guide',
      learnMoreURL: 'https://client.qacitizensbank.com/s/article/Quick-Start-Guide',
      isDisabled: false,
      linkText: 'Download',
      src: `${assetURL}assets/docSmb.png`
    },
    {
      name: 'Training Videos',
      displayName: 'Training Videos',
      isRegistered: true,
      description: 'Ours tool are simple to use, but if you need a hand, check out our training videos.',
      homeURL: 'https://client.qacitizensbank.com/s/knowledgearticledetail?type=CashFlowEssentials',
      learnMoreURL: 'https://client.qacitizensbank.com/s/knowledgearticledetail?type=CashFlowEssentials',
      isDisabled: false,
      linkText:'Watch Videos',
      src: `${assetURL}assets/videoSmb.png`
    },
    {
      name: 'FAQs',
      displayName: 'FAQs',
      isRegistered: false,
      description: 'Need help? Here are answers to some of our most frequently asked questions.',
      homeURL: 'https://client.qacitizensbank.com/s/knowledgearticledetail?type=CashFlowEssentials',
      learnMoreURL: 'https://client.qacitizensbank.com/s/knowledgearticledetail?type=CashFlowEssentials',
      isDisabled: false,
      linkText: 'FAQs',
      src: `${assetURL}assets/faqSmb.png`
    }
  ],

  // external - cbforex
  cb_logoutUrl: 'https://qa.qacbforex.com/pa/oidc/logout.png',
  fqdns: 'https://universalportal-sso.qacitizensbank.com',

  // auth apis
  univ_startCloseSession: "https://universalportal-sso.qacitizensbank.com/auth/startCloseSession",
  univ_cancelCloseSession: "https://universalportal-sso.qacitizensbank.com/auth/cancelCloseSession",

  // persistence api
  univ_saveCreds: `${baseURL}/auth/persist/saveUserProfile`,
  univ_nonOudSaveCreds: `${baseURL}/auth/persist/saveUserProfileWithNonOUDApp/`,
  univ_emailValidation: `${baseURL}/auth/persist/emailValidation`,
  univ_removeCreds: `${baseURL}/auth/persist/removeCredentials`,
  univ_addCreds: `${baseURL}/auth/persist/addCredentials`,
  univ_getcontactdetails: `${baseURL}/auth/persist/getUniversalId`,
  univ_updatecontactdetails: `${baseURL}/auth/persist/updateUniversalUser`,
  univ_getSalePortalUserDetails: `${baseURL}/auth/persist/salesForcePersist/salesForcePersistLists`,
  univ_updateLastLoginDetails: `${baseURL}/auth/persist/updateLastLoginDate/`,
  univ_postSalePortalDetails: `${baseURL}/auth/salesportalservice/salePortalUser`,
  univ_getSalePortalCompanyDetails: `${baseURL}/auth/salesportalservice/userIdMaps`,

  univ_revokePFCookieUrl: 'https://pf-fam-qa.qacitizensbank.com/idp/startSLO.ping?TargetResource=https://client.qacitizensbank.com/s/',
  univ_revokePFCookieUrl_Mobile: 'https://pf-fam-qa.qacitizensbank.com/idp/startSLO.ping?TargetResource=https://universalportal-sso.qacitizensbank.com/sso/uportal/mobile-login',
  univ_endSession: `${baseURL}/auth/destroy`,
  univ_extendSession: `${baseURL}/auth/extend`,
  univ_pkce_authenticate: `${baseURL}/auth/authenticate`,
  univ_getLinkedCredsCount: `${baseURL}/auth/persist/getLinkedLegacyCredentialCount`,
  univ_checkIfCredentialLinked: `${baseURL}/auth/persist/checkIfLegacyIdLinked`,
  univ_deleteUserProfile: `${baseURL}/auth/persist/deleteUserProfile`,
  univ_getCampaignData: `${baseURL}/auth/persist/getCampaignUserData`,
  univ_getCampaignDataByUniqueId: `${baseURL}/auth/persist/getCampaignDataByUniqueId`,
  univ_nonOUDSaveUserProfile: `${baseURL}/auth/persist/addCredentialsWithNonOUDApp`,
  univ_updateSplash: `${baseURL}/auth/persist/updateShowSplash`,
  univ_insights: `${baseURL}/auth/digitalized/businessCIFIds`,
  univ_fraud: `${baseURL}/auth/fraud-prevention/find`,
  univ_checkCredentialsDisabledURL: `${baseURL}/auth/credentialsInfo`,
  univ_saveClassicViewPreference: `${baseURL}/auth/persist/updateUserPreferences`,
  univ_saveFraudPreventionAcknowledgement: `${baseURL}/auth/persist/updateUserProfileJsonData`,
  univ_saveToUserProfileJson: `${baseURL}/auth/persist/updateUserProfileJsonData`,
  univ_saveUserProfileJsonData: `${baseURL}/auth/persist/updateUserProfileJsonData`,
  univ_getLinkedCredsForCompIdAndAppName: `${baseURL}/auth/persist/getLinkedCredsForCompIdAndAppName?compId=ccfqa&appName=ccf`,
  uportalLogin: `${baseURL}/sso/uportal/login`,
  enrollNowUrl: `${baseURL}/sso/uportal/enroll`,
  univ_defaultMobileLogoutUrl: `${baseURL}/sso/uportal/mobile-login`,
  univ_getButlerContactID: `${baseURL}/auth/persist/getSFContactID`,
  monitUserContext: `${baseURL}/auth/getUserContext`,
  univ_getNotificationsUrl: `${baseURL}/notification/v1/notifications/viewList`,

  univ_pageSize: {
    linkedCredentials: 10,
    mobileLinkedCredentials: 10,
    manageCredentials: 10
  },
  univ_alive: {
    tabAliveInterval: 300000,   // millisecs - 5 minutes
    idleTimeout: 900,          // seconds - 15 minutes
    inactiveInterval: 5       // seconds
  },
  univ_defaultLogoutUrl: `${commercialDigitalGateawayURL}/s/`,
  ccfPingDropoffUrl: 'https://ccf.qacitizensbank.com/ccf/auth/injectRefId/',
  nonOUDApps: ['CCF', 'CARBON', 'ESCROW', 'SWAPDEALER', 'COMPASS'],
  mobile_login: {
    smb_url: 'https://www.citizensbank.com/cash-flow-essentials/terms',
    accessOPTIMA_url: 'https://www.citizensbank.com/assets/corporate-finance/pdf/Cash-Management-Terms-and-Conditions.pdf',
    contact_url: 'https://client.citizensbank.com/s/findsupport'
  },
  enrollplayUrl: 'https://p.allego.com/ahVTuFgs5Tws5',
  onlineBankingUrl: 'https://www.citizensbankonline.com/olb-root/login?adobe_mc=TS%3D1716409432%7CMCMID%3D39333024693697076383509549938863781140%7CMCORGID%3D4C3B0C3755C3822E7F000101%2540AdobeOrg',
  enrollLeanMore: 'https://client.qacitizensbank.com/s/knowledgearticledetail?type=UniversalLogin',
  fraudProtectionLearnMore: 'https://www.citizensbank.com/corporate-finance/treasury-management/fraud-protection.aspx?ctzMode=CFG-B2B_CGW_CMC_CTZ_CYBER_CYBERAO',
  commercialServicesCDNUrl: 'https://universalportal-sso.qacitizensbank.com/content/config/commercial-services.json',
  footerLinksCDNUrl: 'https://universalportal-sso.qacitizensbank.com/content/config/footerLinks.json',

  //butler
  sprinklrScriptUrl: 'https://gallery.sprinklr.com/clients/4698/embeds/ZW1iZWQ6NjUzZmYxNmY2OGI5NjE0ZmU0NDUwOTVi/script.js',

  universalLoginQuickGuide: 'https://client.qacitizensbank.com/s/article/Universal-Login',
  universalLoginServiceCase: 'https://client.qacitizensbank.com/s/servicecase',
  erpConnectInfoCDNUrl: 'https://universalportal-sso.qacitizensbank.com/content/config/erp_connect_info.json',
  acctVerfSplashCDNUrl: 'https://universalportal-sso.qacitizensbank.com/content/config/acct_verf_splash_urls.json',

  univ_manage_consent: `${baseURL}/auth/misc/v1/manage-consent/`,
  univ_manage_consent_revoke: `${baseURL}/auth/misc/v1/manage-consent/revoke`,
  cdnUrl: 'https://universalportal-sso.qacitizensbank.com/content/assets/',
  consentManagementFlagCDN: 'https://universalportal-sso.qacitizensbank.com/content/config/show_consent_management.json',
  acc_verif_billingAccountDetails: 'https://universalportal-sso.qacitizensbank.com/utility/v1/billing/billingAccountDetails/'
};
