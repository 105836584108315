import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { AccountVerificationUtils } from './account-verification.utils';


@Injectable({
    providedIn: 'root'
})
export class ApiService {
    public mergedAcknowledgement: any;
    public errorMessage: string = '';

    public currentTabSubject = new BehaviorSubject<string>('');
    currentTab$ = this.currentTabSubject.asObservable();

    private baseUrlBilling = environment.acc_verif_billingAccountDetails;
    private baseUrlHistory = environment.acc_verif_accountHistoryDetails;
    private baseUrlInquireByRefId = environment.acc_verif_inquireByRefId;

    constructor(private https: HttpClient,
                private utils: AccountVerificationUtils) { }

    isMobileDevice() {
        const userAgent = navigator.userAgent;

        if (/iPad|iPhone|iPod/.test(userAgent)) {
            return true;
        }

        if (/windows phone/i.test(userAgent)) {
            return true;
        }

        if (/android/i.test(userAgent)) {
            return true;
        }

        return false;
    }

    tmxProfiling(): Observable<any> {
        const session_id = Math.random().toString(36).slice(2);
        const orgId = 'dhlrdtzc';
        const url = `https://cdn17.citizensbank.com?org_id=${orgId}&service_type=all&session_id=${session_id}`;
        return this.https.post(url, {});
    }

    billingAccountList(compIds): Observable<any> {
        const url = `${environment.acc_verif_billingAccountDetails}${compIds.join(',')}`;
        return this.https.get<any>(url);
    }

    getCountryDetailsFromCDN() {
        const url = environment.acc_verif_countryDetails;
        return this.https.get<any>(url);
      }

    getTermsAndConditionsFromCDN(): Observable<any> {
        const url = environment.acc_verif_termsAndConditionsCDN;
        return this.https.get<any>(url);
    }

    postAccountValidation(httpHeaders, body, companyList): Observable<any> {
        const params = new HttpParams().set('companyIds', companyList.join(','));
        const headers = new HttpHeaders(httpHeaders);
        const url = environment.acc_verif_postAccountValidation;
        return this.https.post<any>(url, body, { headers, params }).pipe(
            catchError(error => {
                if (error.name === 'TimeoutError') {
                    return throwError('Request timed out');
                }
                return throwError('Error during inquire request');
            })
        );
    }

    // inquire by reference id
    inquireByRefId(refId: string, universalId): Observable<any> {
        const headers = new HttpHeaders({
            'Accept': '*/*',
            'universalId': universalId
        });
        const params = new HttpParams().set('referenceId', refId);
        return this.https.get<any>(`${this.baseUrlInquireByRefId}`, { headers, params }).pipe(
          catchError(error => {
            console.error('Error during inquireById request', error);
            return of({ message: 'ERROR', data: null });
          })
        );
    }

    saveTermsValue(termsValue, universalId, mergedAck): Observable<any> {
        const url = environment.acc_univ_saveUserProfileJsonData;

        const mergedAcknowledgements = { ...mergedAck, acctVerificationAck: termsValue };

        const body = {
            universalId: universalId,
            acknowledgements: mergedAcknowledgements
        };

        return this.https.post<any>(url, body);
    }

    setCurrentTab(tab: string) {
        this.currentTabSubject.next(tab);
    }


    checkBillingAccounts(companyList: []): Observable<any> {
        const url = `${this.baseUrlBilling}${companyList.join(',')}`;
        return this.https.get<any>(url);
    }

    checkAccountsHistory(companyList: [], universalId: string): Observable<any> {
        const params = new HttpParams().set('companyId', companyList.join(','));
        const headers = new HttpHeaders({
            'universalId': universalId,
        })
        return this.https.get<any>(this.baseUrlHistory, { headers, params });
    }

    postRoutingNumber(bankId, universalId): Observable<any> {
        const url = `${environment.acc_verif_postRoutingNumber}`+`${bankId}`;
        const headers = new HttpHeaders({
            'Accept': '*/*',
            'universalId': universalId
        });
        return this.https.post<any>(url, '', { headers }).pipe(
            catchError(error => {
                console.error(error);
                if (error.status === 400) {
                    this.errorMessage = this.utils.getErrorMessage('wrongRoutingNumber');
                    return throwError(() => error);
                } else {
                    this.errorMessage = this.utils.getErrorMessage('routingNumberFail');
                    return throwError(() => error);
                }
            })
        );
    }

    getProfilingFlagFromCDN(): Observable<any> {
        const url = environment.acc_verif_profilingFlagCDN;
        return this.https.get<any>(url);
    }
}